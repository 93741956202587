export const getCurrentTime = () => {
  const myDate = new Date();
  let year: string | number = myDate.getFullYear();
  let month: string | number = myDate.getMonth() + 1;
  let day: string | number = myDate.getDate();
  if (year < 10) {
    year = "0" + year;
  }
  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }
  const time = year + "-" + month + "-" + day;
  return time;
}