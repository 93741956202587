<template>
  <div id="about" class="center">
    <div class="left">
      <div class="message">
        <div class="title-wrapper">
          <div class="title">留言</div>
        </div>
        <a-form
          ref="ruleForm"
          :model="Message"
          :rules="messageRules"    
        >
          <a-form-item name="username">
            <a-input placeholder="昵称" v-model:value="Message.username" />
          </a-form-item>
          <a-form-item name="content">
            <a-textarea placeholder="留言内容" v-model:value="Message.content" />
          </a-form-item>
        </a-form>
        <a-button :loading="loading" @click="publish" class="submit-btn">发布留言</a-button>
      </div>
      <div class="new-message">
        <div class="title-wrapper">
          <div class="title">最新留言</div>
        </div>
        <ul>
          <li v-for="item in Comments" :key="item.id">
            <card-message :comment="item"></card-message>
          </li>
        </ul>
        <div style="text-align: center;">
          <a-pagination 
            v-model:current="pagination.current" 
            :total="pagination.total" 
            v-model:pageSize="pagination.pagesize"
            show-less-items 
            @change="changePage"
          />
        </div>
      </div>
      <div class="f-link">
        <div class="title-wrapper">
          <div class="title">友情链接</div>
        </div>
        <div class="link">
          <a :href="item.link" target="_blank" v-for="item in links" :key="item.id">
            <img :src="item.logo" alt="">
            <div class="info">
              <h2>{{ item.title }}</h2>
              <p>{{ item.info }}</p>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="me">
        <div class="title-wrapper">
          <div class="title">关于</div>
        </div>
        <div class="logo">
          <img src="@/assets/img/logo.jpg" alt="">
        </div>
        <div class="name">木小天</div>
        <div class="detail">
          <p>
            欢迎访问木小天博客，这是我的个人网站。此网站我会分享一些文章，也许会有许多的缺陷和不足，
            如果你有一些好的想法、创意的话，可以发邮件给我。
          </p>
          <p>邮箱地址:mxtiancn@qq.com</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import cardMessage from "@/components/card-message/card-message.vue"
import { leavingMessage, Comments } from "@/common/message"
import { getLeavingMsg, getLinks } from "@/api"

export default defineComponent({
  components: {
    cardMessage
  },
  setup() {   
    // 友情链接
    const links = ref(null);
    // 获取评论参数
    const articleParams = {
      pagenum: 1,
      pagesize: 5
    }
    // 分页参数
    const pagination = ref({
      current: 1,
      total: 7,
      pagesize: articleParams.pagesize
    })

    // 分页
    const changePage = (page: number) => {
      getLeavingMsg({pagenum: page, pagesize: 7}).then((res: any) => {
        Comments.value = res.data.data.messages
      })
    }

    onMounted(() => {
      leavingMessage.Message.username = "";
      leavingMessage.Message.content = "";
      getLeavingMsg({pagenum: 1, pagesize: 7}).then((res: any) => {
        Comments.value = res.data.data.messages
        pagination.value.total = res.data.data.total
      })
      getLinks().then((res: any) => {
        links.value = res.data.data;
      })
    })

    return {
      ...leavingMessage,
      links,
      Comments,
      pagination,
      changePage
    }
  }

})
</script>

<style lang="scss" scoped>
  #about {
    display: flex;
    justify-content: space-between;

    .left {
      width: 73%;
      padding: 20px;
      background: white;
      
      .message {

        ::v-deep(.ant-col) {
          width: 100%;
        }

        .submit-btn {
          display: block;
          width: 100%;
          height: 40px;
          cursor: pointer;
          border-radius: 5px;
          -moz-border-radius: 5px;
          -webkit-border-radius: 5px;
          background: #2254f4;
          color: #fff;
          font-size: 14px;
          text-align: center;
          line-height: 40px;
          background: linear-gradient(270deg,#2254f4,#406dff);
          box-shadow: 0 12px 30px 0 rgba(34,84,244,.2);
          user-select: none;
        }
      }

      .new-message {
        margin-top: 20px;

        ul {

          li {
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px dashed #eee; 
          }
        }
      }

      .f-link {

        .link {
          display: flex;
          flex-wrap: wrap;

          a {
            width: 32%;
            margin-right: 2%;
            margin-top: 10px;
            padding: 10px;
            border-radius: 6px;
            font-size: 13px;
            color: #666;
            border: 1px solid #eee;
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: all .3s;

            &:nth-of-type(3n) {
              margin-right: 0;
            }

            &:hover {
              box-shadow: 0 20px 60px rgba(61, 79, 127, 0.17);
              transform: translate3d(0, -7px, 0);

              h2 {
                color: #2254f4;
              }
            }

            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              margin-right: 10px;
            }

            .info {

              h2 {
                font-size: 13px;
                margin: 0;
                font-weight: 600;
              }

              p {
                font-size: 10px;
                color: #999;
                margin: 0;
              }
            }
          }
        }
      }
    }

    .right {
      width: 25%;

      .me {
        background: #fff;
        border-radius: 6px;
        padding: 20px;

        .logo {
          display: flex;
          justify-content: center;
          padding: 3px 0px;

          img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
          }
        }

        .name {
          font-weight: 600;
          text-align: center;
          padding-bottom: 10px;
          border-bottom: 1px solid #eee;
        }

        .detail {
          font-size: 13px;
          color: #999;
          line-height: 20px;
          margin-top: 25px;
          margin-bottom: 20px;

          p {
            text-indent: 2em;
          }
        }
      }
    }
  }

   // 移动端
  @media screen and (max-width: 800px) {
    #about {
      flex-direction: column-reverse;
      flex-wrap: wrap;

      .left {
        width: 100%;

        .f-link {

          .link {
            display: block;

            a {
              width: 100%;
            }
          }
        }
      }

      .right {
        width: 100%;
      }
    }
  }
</style>