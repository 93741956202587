import { reactive, ref } from "vue";
import { message } from 'ant-design-vue';
import { getCurrentTime } from "@/common/currentTime"
import { addArtComment, addLeavingMsg } from "@/api"

const Message = reactive({
  username: "",
  content: ""
})

interface Comment {
  artid?: number;
  username: string;
  content: string;
  time: string;
}
// 加载
const loading = ref(false);
// 展示数据
export const Comments = ref<Array<Comment>>([])
// 规则
const ruleForm = ref(null);
// 表单验证规则
const messageRules = {
  username: [
    { required: true, message: '请输入昵称', trigger: 'blur' },
    { min: 1, max: 8, message: '昵称长度应为1-8位', trigger: 'blur' }
  ],
  content: [
    { required: true, message: '请输入内容', trigger: 'blur' },
    { min: 1, max: 100, message: '内容长度应为1-100位', trigger: 'blur' },
  ]
};
// 提交
const publish = (artid: number, sign = 1) => {
  (ruleForm.value as any).validate().then(() => {
    const data = {
      artid: artid,
      username: Message.username, 
      content: Message.content,
      time: ''
    }
    const time = getCurrentTime();
    loading.value = true;
    if (sign == 0) {
      addArtComment(data).then((res: any) => {
        loading.value = false;
        if (res.code == 500) {
          Message.username = "";
          Message.content = "";
          return message.error(res.data.message);
        }
        message.success(res.data.message);
        data.time = time;
        Comments.value.unshift(data)
        Message.username = "";
        Message.content = "";
      }).catch(() => {
        message.error("评论失败！");
      })
    } else {
      addLeavingMsg(data).then((res: any) => {
        loading.value = false;
        if (res.code == 500) {
          Message.username = "";
          Message.content = "";
          return message.error(res.data.message);
        }
        message.success(res.data.message);
        data.time = time;
        Comments.value.unshift(data)
        Message.username = "";
        Message.content = "";
      }).catch(() => {
        message.error("留言失败！");
      })
    }
  })
}

export const leavingMessage = {
  Message,
  loading,
  ruleForm,
  messageRules,
  publish
}