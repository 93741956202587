<template>
  <div id="card-message" v-if="comment">
    <div class="msg-top">
      <div class="name"># {{ comment.username }}</div>
      <div class="time">{{ comment.time }}</div>
    </div>
    <div class="detail-text">
      <span>{{ comment.content }}</span>
    </div>
    <div class="other-info" v-if="comment.reply">
      <strong style="color:#409eff">木小天</strong>：{{ comment.reply }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    comment: Object
  }
})
</script>

<style lang="scss" scoped>
  #card-message {
    .msg-top {
      display: flex;
      align-items: center;

      .name {
        font-size: 15px;
        color: #333;
        line-height: 24px;
      }

      .time {
        font-size: 12px;
        color: #999;
        margin-left: 10px;
      }
    }

    .detail-text {
      margin: 10px 0;
      font-size: 13px;
      color: #666;
      padding: 15px;
      border-radius: 4px;
      background: #f6f6f6;
    }

    .other-info {
      font-size: 12px;
      color: #666;
    }
  }
</style>