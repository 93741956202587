
import { defineComponent, onMounted, ref } from "vue";
import cardMessage from "@/components/card-message/card-message.vue"
import { leavingMessage, Comments } from "@/common/message"
import { getLeavingMsg, getLinks } from "@/api"

export default defineComponent({
  components: {
    cardMessage
  },
  setup() {   
    // 友情链接
    const links = ref(null);
    // 获取评论参数
    const articleParams = {
      pagenum: 1,
      pagesize: 5
    }
    // 分页参数
    const pagination = ref({
      current: 1,
      total: 7,
      pagesize: articleParams.pagesize
    })

    // 分页
    const changePage = (page: number) => {
      getLeavingMsg({pagenum: page, pagesize: 7}).then((res: any) => {
        Comments.value = res.data.data.messages
      })
    }

    onMounted(() => {
      leavingMessage.Message.username = "";
      leavingMessage.Message.content = "";
      getLeavingMsg({pagenum: 1, pagesize: 7}).then((res: any) => {
        Comments.value = res.data.data.messages
        pagination.value.total = res.data.data.total
      })
      getLinks().then((res: any) => {
        links.value = res.data.data;
      })
    })

    return {
      ...leavingMessage,
      links,
      Comments,
      pagination,
      changePage
    }
  }

})
